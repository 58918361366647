// check if there is an imagemapcontainer on the page
const imagemapContainer = document.querySelector('.imagemapcontainer');
if(imagemapContainer) {

	// imagemap
	const circles = document.querySelectorAll('.imagemap__image__circle');
	const descriptions = document.querySelectorAll('.imagemap_desc__item');

	circles.forEach(circle => {
		circle.addEventListener('click', () => {
			const description = circle.dataset.description;

			// if this circle has the class active alreade then remove it
			if(circle.classList.contains('active')) {
				circle.classList.remove('active');
				document.querySelector(`.imagemap_desc__item[data-description="${description}"]`).classList.remove('active');
			} else {
				descriptions.forEach(description => description.classList.remove('active'));
				circles.forEach(circle => circle.classList.remove('active'));
				circle.classList.add('active');
				document.querySelector(`.imagemap_desc__item[data-description="${description}"]`).classList.add('active');
			}
		});
	});

}

// video element
const supportsVideo = !!document.createElement("video").canPlayType;
if (supportsVideo) {
// Select all video elements on the page
	const videos = document.querySelectorAll("video");

// Loop through each video element
	videos.forEach((video) => {
		// Hide the default controls
		video.controls = false;

		// Display the user defined video controls
		const playpause = video.nextElementSibling.querySelectorAll('.video__controls__play');

		// Add a click event listener to the play/pause button
		playpause.forEach(playpause => {
			playpause.addEventListener("click", (e) => {
				if (video.paused || video.ended) {
					video.play();
					playpause.classList.add('act');
					video.parentElement.classList.add('act');

				} else {
					video.pause();
					playpause.classList.remove('act');
					video.parentElement.classList.remove('act');
				}
			});
		});

	});
}

// accordion

const faqItems = document.querySelectorAll('.faq__item');

faqItems.forEach(item => {
	const question = item.querySelector('.faq__question');
	question.addEventListener('click', () => {
		const isOpen = item.classList.contains('open');

		// get the height of the clicked answer
		const answer = item.querySelector('.faq__answer');
		const answerHeight = answer.scrollHeight;

		if (!isOpen) {
			item.classList.add('open');
			answer.style.height = answerHeight + 'px';
		} else {
			item.classList.remove('open');
			answer.style.height = '0';
		}
	});
});


// chuckup more content
const moreItems = document.querySelectorAll('.chuckup__more__item');

moreItems.forEach(item => {
	const question = item.querySelector('.chuckup__more__title');
	question.addEventListener('click', () => {
		const isOpen = item.classList.contains('open');

		// close all open items and set the css height to zero
		moreItems.forEach(item => {
			item.classList.remove('open');
			const answer = item.querySelector('.chuckup__more__content');
			answer.style.height = '0';
		});

		// get the height of the clicked answer
		const answer = item.querySelector('.chuckup__more__content');
		const answerHeight = answer.scrollHeight;

		if (!isOpen) {
			item.classList.add('open');
			answer.style.height = answerHeight + 'px';
		}
	});
});

// chuckup selector
// if you click on the selector chuckup__model then check if it has the class act
// if it has the class act then remove it and hide the div container with the corresponding id
// if it has not the class act then add it and show the div container with the corresponding id
const chuckUpModel = document.querySelectorAll('.js--chuckup__model');
chuckUpModel.forEach(model => {
	model.addEventListener('click', () => {
		const isOpen = model.classList.contains('act');
		// get data-chuckup-model="2" from the chuckup__model element
		const id = model.dataset.chuckupModel;

		if (!isOpen) {
			// add the class to all elements with the same id
			model.parentElement.parentElement.querySelectorAll(`.js--chuckup__model[data-chuckup-model="${id}"], .js--chuckup__content[data-chuckup-model="${id}"]`).forEach(chuck => {
				chuck.classList.add('act');
			});


			// remove the class from other elements with the same class but not the same id
			model.parentElement.parentElement.querySelectorAll('.js--chuckup__model, .js--chuckup__content').forEach(model => {
				if(model.dataset.chuckupModel != id) {
					model.classList.remove('act');
				}
			});
		}
	});
});


// 2-click consent function for the youtube mask element
// get the label text of the consent button
var maskYoutube = document.querySelectorAll('.mask_youtube');
if(maskYoutube) {
	maskYoutube.forEach(function(element) {
		var youtubeLabelEnable = element.querySelector('.mask_youtube__consent-button').dataset.youtubeTextEnable;
		var youtubeLabelDisable = element.querySelector('.mask_youtube__consent-button').dataset.youtubeTextDisable;

		// function that activates videos after youtube consent was set
		var activateVideo = function() {
			// load every video iframe on the page if the consent button gets clicked
			document.querySelectorAll('.mask_youtube__video').forEach(function(video) {
				video.classList.add('act');
				// get the video src from the data attribute
				var iframeSrc = video.querySelector('.mask_youtube__iframe-src').dataset.src;
				// get the iframe video object
				var emptyIframe = video.querySelector('.mask_youtube__iframe');

				// add src to the empty iframe
				emptyIframe.setAttribute('src', iframeSrc);
			});

			// switch the mask_youtube__consent button class and text
			document.querySelectorAll('.mask_youtube__consent-button').forEach(function(videoButton) {
				youtubeLabelEnable;
				youtubeLabelDisable;

				videoButton.classList.add('act');
				videoButton.innerHTML = youtubeLabelDisable;
			});

			sessionStorage.setItem('youtube_consent', '1');
		}

		var deactivateVideo = function() {
			document.querySelectorAll('.mask_youtube__video').forEach(function(video) {
				video.classList.remove('act');
				video.querySelector('.mask_youtube__iframe').removeAttribute("src");
			});

			document.querySelectorAll('.mask_youtube__consent-button').forEach(function(videoButton) {
				youtubeLabelEnable;
				youtubeLabelDisable;

				videoButton.classList.remove("act");
				videoButton.innerHTML = youtubeLabelEnable;
			});

			sessionStorage.removeItem('youtube_consent');
		}

		if(sessionStorage.getItem('youtube_consent') == 1) {
			activateVideo();
		}

		element.querySelector('.mask_youtube__consent-button').addEventListener( "click", function() {
			if(this.classList.contains('act')) {
				deactivateVideo();
			} else {
				activateVideo();
			}
		});
	});
}


// step by step instruction

// spindle diameter result
if(document.querySelector(".instruction__select__item")) {

	// instruction select
	// add click event listener to the class instruction__select__item
	const instructionSelect = document.querySelectorAll('.instruction__select__item');

	instructionSelect.forEach(item => {

		// get the id from the instruction__select__item element with class act inside the previous gridcontainer element
		const selectedID = item.dataset.instructionSelect;

		// get the data attribute from data-chuckup-inch
		const selectedInch = item.dataset.chuckupInch;

		// get the spindlestop get parameter from the url
		const urlParams = new URLSearchParams(window.location.search);
		const spindlestop = urlParams.get('spindlestop');

		var chuckupResult = 1;

		// set a result parameter depending on spindlestop and select ID

		// results withtout spindlestop (spindlestop == 0)
		if(spindlestop == 0 && selectedID == 1) {
			// selectID 1 = 9/16 inch spindle diameter
			chuckupResult = 10 // chuckup no. 10
		}

		if(spindlestop == 0 && selectedID == 2) {
			// selectID 2 = 1/2 inch spindle diameter
			chuckupResult = 2 // chuckup no. 2
		}

		if(spindlestop == 0 && selectedID == 3) {
			// selectID 3 = 3/8 inch spindle diameter
			chuckupResult = 20 // chuckup no. 20
		}

		// results witht spindlestop (spindlestop == 1)

		if(spindlestop == 1 && selectedID == 1) {
			// selectID 1 = 9/16 inch spindle diameter
			chuckupResult = 4 // chuckup no. 4 and 6;
		}

		if(spindlestop == 1 && selectedID == 2) {
			// selectID 2 = 1/2 inch spindle diameter
			chuckupResult = 3 // chuckup no. 3 and 5;
		}

		if(spindlestop == 1 && selectedID == 3) {
			// selectID 3 = 3/8 inch spindle diameter
			chuckupResult = 1 // chuckup no. 1;
		}


		// add the chuckupResult to the current href of the button as get parameter
		// if there is already a get parameter then add the chuckupResult as new get parameter
		if(item.href.indexOf('?') > -1) {
			// overwrite the chuckupResult if there is already a spindlestop get parameter
			item.href = item.href.replace(/chuckup=[^&]+/, 'chuckup=' + chuckupResult);

		} else {
			// add the chuckupResult as get parameter
			item.href += `?chuckup=${chuckupResult}`;

			// add spindlestop to the get parameter
			item.href += `&spindlestop=${spindlestop}`;

			// add selected inch to the get parameter
			item.href += `&inch=${selectedInch}`;

			// add anchor to the href
			item.href += `#chuckupresult`;

		}

		item.addEventListener('click', () => {
			const isAct = item.classList.contains('act');


			// remove the results
			document.querySelectorAll('.instruction__select__result').forEach(item => {
				item.classList.remove('act');
			});

			// add the class act to the clicked element
			if(!isAct) {
				// remove the class act from other elements with the same class
				document.querySelectorAll('.instruction__select__item').forEach(item => {
					if(item != this) {
						item.classList.remove('act');
					}
				});

				item.classList.add('act');


			} else {
				item.classList.remove('act');
			}

		});
	});
}

// manual spindlecheck results page
// check if there is a chuckup get parameter in the url
const urlParams = new URLSearchParams(window.location.search);
const chuckupResultUrl = urlParams.get('chuckup');
if(chuckupResultUrl) {
	// get the spindlestop and the inch get parameter from the url
	const spindlestop = urlParams.get('spindlestop');
	const inch = urlParams.get('inch');

	var spindlestopText;

	var inchTextValue;
	var inchText;

	if(spindlestop == 0) {
		spindlestopText = "ohne Spindelstopp";
		// if html lang is en
		if(document.documentElement.lang == "en") {
			spindlestopText = "without spindle stop";
		}
	} else if (spindlestop == 1) {
		spindlestopText = "mit Spindelstopp";
		if(document.documentElement.lang == "en") {
			spindlestopText = "with spindle stop";
		}
	}

	if(inch == "3-8") {
		inchTextValue = "3/8";
	} else if (inch == "1-2") {
		inchTextValue = "1/2";
	} else if (inch == "9-16") {
		inchTextValue = "9/16";
	}

	inchText = inchTextValue + " Zoll Durchmesser";
	// if html lang is en
	if(document.documentElement.lang == "en") {
		inchText = inchTextValue + " inch diameter";
	}

	var resultText = spindlestopText + " / " + inchText;

	// add the values to the manualdrillchuckresult__value class
	document.querySelectorAll('.manualdrillchuckresult__value').forEach(item => {
		item.innerHTML = resultText;
	});


	// add the class act to the element with the corresponding id
	var chuckContainer = document.getElementById("chuckupconfigurator__chuck-" + chuckupResultUrl);
	// show the chuckupconfigurator__chuck element
	chuckContainer.style.display = "block";
}



// select a box and scroll to its corresponding content
function selectBoxClick (selectBoxesElements) {
	// example for the selectbox
	// <div className="selectbox" data-selectboxcontent="content-1">Auswahlbox 1</div>

	// example for the selected content
	// <div class="selectbox__content" id="content-1">

	// get the class attribute of the first selectBoxesElements
	const selectorClass = this.getAttribute ("class");
	// the class attribute has multiple class names. Remove all of them and keep only the one which starts with selectbox__
	const selectboxClass = selectorClass.match(/\bselectbox__[^\s]+\b/)[0];

	var selectBoxes = document.querySelectorAll ("." + selectboxClass);

	// check the data-selectboxcontent property of the selected selectbox
	var dataContent = this.getAttribute ("data-selectboxcontent");

	// find the corresponding content by looking for the element with the same id
	var content = document.getElementById (dataContent);

	// add a short delay before scrolling to the content with scrollIntoView()
	setTimeout (function () {
		content.scrollIntoView ({behavior: "smooth", block: "start"});
	}, 200);

	// disable and hide all other select boxes and content
	for (var i = 0; i < selectBoxes.length; i++) {
		if (selectBoxes [i] !== this) {
			selectBoxes [i].classList.remove ("act");
			var otherContent = document.getElementById (selectBoxes [i].getAttribute ("data-selectboxcontent"));
			otherContent.classList.remove ("act");
		}
	}

	// add the class active to the selected selectbox and content
	this.classList.add ("act");
	content.classList.add ("act");
}

// the selectboxes to switch between chuckup determination and assembly instruction
var selectBoxesDetermineAssembly = document.querySelectorAll (".selectbox__determine__assembly");
if(selectBoxesDetermineAssembly) {
	// add an event listener to the click event on each selectbox
	for (var i = 0; i < selectBoxesDetermineAssembly.length; i++) {
		selectBoxesDetermineAssembly [i].addEventListener ("click", selectBoxClick);
	}
}

// the selectboxes for the video / step-by-step part to determine the chuck up
var selectBoxesDetermineChuckMedia = document.querySelectorAll (".selectbox__determine__chuck__media");
if(selectBoxesDetermineChuckMedia) {
	// add an event listener to the click event on each selectbox
	for (var i = 0; i < selectBoxesDetermineChuckMedia.length; i++) {
		selectBoxesDetermineChuckMedia [i].addEventListener ("click", selectBoxClick);
	}
}

// the selectboxes for the assemlby instruction with and without spindle stop
var selectBoxesAssemblySpindle = document.querySelectorAll (".selectbox__assembly__spindle");
if(selectBoxesAssemblySpindle) {
	// add an event listener to the click event on each selectbox
	for (var i = 0; i < selectBoxesAssemblySpindle.length; i++) {
		selectBoxesAssemblySpindle [i].addEventListener ("click", selectBoxClick);
	}
}

// video opt-in
// check if there are video elements with the class video__optin on the page
const videoOptin = document.querySelectorAll('.video__optin');
if(videoOptin) {
	// add an event listener to the click event on each video__optin element
	videoOptin.forEach(item => {
		// append a layer over the video elements to prevent clicking on the video
		const videoLayer = document.createElement('div');
		videoLayer.classList.add('video__optin__layer');

		// add a div container with the class video__optin__text and the data privacy text inside the video__optin__layer element
		const videoText = document.createElement('div');
		videoText.classList.add('video__optin__text');
		videoText.innerHTML = 'Mit dem Aufruf des Videos erklärst du dich einverstanden, dass Deine Daten an YouTube übermittelt werden und dass du die <a href="https://www.roehm.biz/navigationsseiten/datenschutz/" class="link-external" target="_blank">Datenschutzerklärung</a> gelesen hast<br> <a class="glightbox4 link-button link-button--big video__optin__play">Video starten</a>';

		// add videoText for the english version
		if(document.documentElement.lang == "en") {
			videoText.innerHTML = 'By accessing the video, you agree that your data will be transmitted to YouTube and that you have read the <a href="https://www.roehm.biz/en/navigationsseiten/data-protection/" class="link-external" target="_blank">data protection</a><br> <a class="glightbox4 link-button link-button--big video__optin__play">Start video</a>';
		}
		videoLayer.appendChild(videoText);


		// append the layer to the glightbox4 element
		const lightboxVideo = item.querySelector('.video__optin__link');
		lightboxVideo.appendChild(videoLayer);

		// prevent clicking on the a tag inside the video element
		const videoLink = item.querySelector('a.video__optin__link');
		// get the data-videohref attribute from the videoLink element
		const videoHref = videoLink.dataset.videohref;

		// add an event listener to the click event on the video__optin__play element
		const videoPlay = item.querySelector('.video__optin__play');
		// add the videoHref to the href of the videoPlay element
		videoPlay.setAttribute('href', videoHref);
	});
}


// to top button
if(document.querySelector(".top__arrow")) {
	// show the top__arrow element if the user scrolls down (use the intersection observer)
	const topArrow = document.querySelector('.top__arrow');
	const topArrowObserver = new IntersectionObserver(function(entries) {
		entries.forEach(entry => {
			if (entry.boundingClientRect.top < 0) {
				topArrow.classList.add('act');
			} else {
				topArrow.classList.remove('act');
			}
		});

	});
	topArrowObserver.observe(document.querySelector('.js--scroll--activator'));

	// scroll to top on click on the top__arrow element
	topArrow.addEventListener('click', () => {
		window.scrollTo({top: 0, behavior: 'smooth'});
	});
}


// glightbox
var lightbox = GLightbox();

var lightboxDescription = GLightbox({
	selector: '.glightbox2'
});
var lightboxVideo = GLightbox({
	selector: '.glightbox3',
	autoplayVideos: true,
	touchNavigation: false,
	preload: true
});


lightboxVideo.on('slide_changed', ({ prev, current }) => {
	console.log('Prev slide', prev);
	console.log('Current slide', current);

	const { slideIndex, slideNode, slideConfig, player } = current;

	if (player) {
		if (!player.ready) {
			// If player is not ready
			player.on('ready', (event) => {
				// Do something when video is ready
			});
		}

		player.on('play', (event) => {
			console.log('Started play');
		});

		player.on('volumechange', (event) => {
			console.log('Volume change');
		});

		player.on('ended', (event) => {
			console.log('Video ended');
		});
	}
});


var lightboxInlineIframe = GLightbox({
	selector: '.glightbox4',
	autoplayVideos: true,
	touchNavigation: false
});

// shop drill chuck size opt-in
// check if there is a drill chuck size opt-in class chuckup__sizeoptin__checkbox on the page
const drillChuckSizeOptin = document.querySelectorAll('.chuckup__sizeoptin__checkbox');

if (drillChuckSizeOptin.length > 0) {
	// add an event listener to the click event on each drillChuckSizeOptin element
	drillChuckSizeOptin.forEach(item => {
		// check if the chuckup__sizeoptin__checkbox is checked or not on change and on load of the checkbox

		const isChecked = item.checked;
		console.log(isChecked);

		// if the checkbox is checked then show the chuckup__sizeoptin__content element
		if(isChecked) {
			item.parentElement.parentElement.classList.remove('chuckup__content__shoplinks--disabled');
		} else {
			item.parentElement.parentElement.classList.add('chuckup__content__shoplinks--disabled');
		}

		item.addEventListener('change', () => {
			const isChecked = item.checked;
			if(isChecked) {
				item.parentElement.parentElement.classList.remove('chuckup__content__shoplinks--disabled');
			} else {
				item.parentElement.parentElement.classList.add('chuckup__content__shoplinks--disabled');
			}
		});


	});
}
